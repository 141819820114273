import Link from "next/link";
import * as React from "react";
import { Nav } from "@/types/index";
import NavSubMenu from "./NavSubMenu";
import styles from "./styles.module.css";
import Image from "next/image";

const DesktopNav: React.FC<{
	hideMenu: Boolean;
	logoLink: string;
	nav: Nav;
}> = ({ hideMenu, logoLink, nav }) => {
	// Render the first level Nav options.
	const navItems = nav.map((item: any) => {
		return (
			<li key={item.title}>
				<span>
					<Link href={item.href}>{item.title}</Link>
					{item.subMenu.length > 0 ? (
						<div className={styles.nav__submenu}>
							<NavSubMenu subMenu={item.subMenu} />
						</div>
					) : (
						""
					)}
				</span>
			</li>
		);
	});

	return (
		<div className="container position-relative">
			<div className="d-flex flex-fill">
				<Link href={logoLink} className={styles.logo_dark}>
					<span className={styles.logo_dark}>Savely.</span>
				</Link>
				<div className={styles.nav}>{!hideMenu && <ul>{navItems}</ul>}</div>
				{logoLink == "/solar" ? (
					<div className="position-relative pt-3">
						<Image
							src="/logos/scottish-power.svg"
							alt="Scottish Power logo."
							width={"235"}
							height={"35"}
						/>
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default DesktopNav;
