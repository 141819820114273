import Link from "next/link";
import * as React from "react";
import { Nav } from "@/types/index";
import styles from "./styles.module.css";
import MobileMenuList from "./MobileMenuList";
import Image from "next/image";

const MobileNav: React.FC<{
	hideMenu: Boolean;
	logoLink: string;
	nav: Nav;
}> = ({ hideMenu, logoLink, nav }) => {
	const [isOpen, setIsOpen] = React.useState<Boolean>(false);

	const onClick = () => {
		setIsOpen(!isOpen);
	};

	return (
		<>
			<div className="container">
				<div className="d-flex flex-fill flex-wrap justify-content-center">
					<Link href={logoLink} className={styles.logo_dark}>
						<span className={styles.logo_dark}>Savely.</span>
					</Link>

					{!hideMenu && (
						<div className={styles.nav__mobile}>
							<div
								className={styles["nav__mobile-menu-icon"]}
								onClick={onClick}
							>
								<div className={styles["nav__mobile-menu-icon-label"]}>
									{isOpen ? "Close" : "Menu"}
								</div>
								{isOpen ? (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="36"
										height="36"
										fill="currentColor"
										className="bi bi-x-square"
										viewBox="0 0 16 16"
									>
										<path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
										<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
									</svg>
								) : (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="36"
										height="36"
										fill="currentColor"
										className="bi bi-list"
										viewBox="0 0 16 16"
									>
										<path
											fillRule="evenodd"
											d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
										/>
									</svg>
								)}
							</div>
						</div>
					)}

					{logoLink == "/solar" ? (
						<div className="position-relative pt-3 ps-2">
							<Image
								src="/logos/scottish-power.svg"
								alt="Scottish Power logo."
								width={"240"}
								height={"58"}
							/>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
			<div
				className={`${styles["nav__mobile-menu"]} ${
					isOpen
						? styles["nav__mobile-menu-open"]
						: styles["nav__mobile-menu-closed"]
				}`}
			>
				<div className={`${styles.menu__items}`}>
					<ul>
						<MobileMenuList items={nav} isChild={false} />
					</ul>
				</div>
			</div>
		</>
	);
};

export default MobileNav;
